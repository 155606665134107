<template>
	<div class="m1">
		<div class="hd ">
			<div class="h1">
				<el-breadcrumb separator="/" style="margin-top: 1.5rem;">
					<el-breadcrumb-item :to="{ path: '/gjsz' }"><span
							style="color: rgba(43, 222, 201, 0.4);">公司列表</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">{{projectName}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item v-if="Xs != 1"><span
							style="color: rgba(43, 222, 201, .4);">{{projectName1}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item v-if="Xs != 2 && Xs != 1"><span
							style="color: rgba(43, 222, 201, .4);">{{projectName2}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, 1);">设备列表</span></el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="back" @click="back()">
				返回
			</div>
		</div>
		<div id=""
			style="margin-left: 1rem; margin-top: 1rem; color: #FFFFFF; height: 5rem; line-height: 5rem; margin-bottom: 1rem;">
			设备列表
		</div>
		<div style="height: 8.4rem;">
			<span
				style="display: inline-block; width: 10rem; height: 6.4rem; margin-left: 1rem; margin-right: 3rem; line-height: 6.4rem; text-align: center;font-size: 1.8rem;"
				@click="Hj(0)" :class="index_c == 0 ? 'bg4' : 'bg3' ">
				环境设备
			</span>
			<span
				style="display: inline-block; width: 10rem; height: 6.4rem;line-height: 6.4rem; text-align: center;font-size: 1.8rem;"
				@click="Hj(1)" :class="index_c == 1 ? 'bg4' : 'bg3' ">
				能源设备
			</span>
		</div>

		<!-- 列表 -->
		<div class="lb">

			<div class="hd bg">
				<!-- 账号密码 -->
				<div>
					<span class="hd_1">
						序号
					</span>
					<span class="hd_2" style="width: 7.7%;">
						<div style="margin-left: 1rem;">
							设备名称
						</div>
					</span>
					<span class="hd_2" style="width: 15.9%;">
						<div style="margin-left: 1rem;">
							设备编号
						</div>
					</span>
					<span class="hd_2" style="width: 15.6%;">
						<div style="margin-left: 1rem;">
							物联编号
						</div>
					</span>
<!-- 					<span class="hd_2" style="width: 7.7%;">
						<div style="margin-left: 1rem;">
							电压等级
						</div>
					</span> -->
					<span class="hd_2" style="width: 7.7%;">
						<div style="margin-left: 1rem;">
							设备状态
						</div>
					</span>
					<span class="hd_2" style="width: 11.5%;">
						<div style="margin-left: 1rem;">
							生产日期
						</div>
					</span>
					<span class="hd_2" style="width: 14.2%;">
						<div style="margin-left: 1rem;">
							生产厂家
						</div>
					</span>
					<span class="hd_2" style="width: 18.6%;">
						<div style="text-align: center;">
							操作
						</div>
					</span>
				</div>
			</div>

			<!-- 主体 -->
			<div style="margin-top: 2rem;">
				<div class="main" v-for="(item,index) in Data" :key="index" @click="To(item)"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div style="width: 100%;display: flex;">
						<div class="m_1">
							{{index + 1}}
						</div>
						<span class="m_2 z1" style="width: 7.7%;">
							<div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
								{{item.name}}
							</div>
							<div class="hidden-text">{{item.name}}</div>
						</span>
						<span class="m_2 z1" style="width: 15.9%;">
							<div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
								{{item.serialNumber}}
							</div>
							<div class="hidden-text">{{item.serialNumber}}</div>
						</span>
						<span class="m_2 z1" style="width: 15.6%;">
							<div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
								{{item.monitorSerialNumber}}
							</div>
							<div class="hidden-text">{{item.monitorSerialNumber}}</div>
						</span>
<!-- 						<span class="m_2" style="width: 7.7%;">
							<div style="margin-left: 1rem;">
								{{item.voltageLevel}}
							</div>
						</span> -->
						<span class="m_2" style="width: 7.7%;">
							<div style="margin-left: 1rem;">
								{{sbzt[item.state]}}
							</div>
						</span>
						<span class="m_2" style="width: 11.5%;">
							<div style="margin-left: 1rem;">
								{{item.productionData}}
							</div>
						</span>
						<span class="m_2 z1" style="width: 14.2%;">
							<div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
								{{item.manufactor}}
							</div>
							<div class="hidden-text">{{item.manufactor}}</div>
						</span>
						<span class="m_2" style="width: 18.6%;">
							<div style="margin-left: 1rem; text-align: center; color: rgba(43, 201, 222, 1);">
								告警设置
							</div>
						</span>
					</div>
				</div>
			</div>

		</div>

		<!-- 翻页 -->
		<div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
			<!-- <span class="demonstration">完整功能</span> -->
			<el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'gsgly',
		data() {
			return {
				index_c: 0,
				sbzt: ['在线', '离线', '异常'],
				list: [{
						name: '在线检测',
						qr: false
					},
					{
						name: '项目管理',
						qr: false
					}, {
						name: '设备管理',
						qr: false
					}, {
						name: '施工管理',
						qr: false
					}, {
						name: '告警管理',
						qr: false
					}, {
						name: '账号管理',
						qr: false
					}


				],
				value: true,
				currentPage4: 1,
				Data: [],
				total: null,
				Xs: null,
				projectName: '',
				projectName1: '',
				projectName2: ''
			}
		},
		created() {
			console.log(this.$route.query.id, this.$route.query.pid, this.$route.query.xs);
			this.Xs = this.$route.query.xs
			let arr1 = decodeURIComponent(this.$route.query.projectName)
			arr1 = JSON.parse(arr1)
			this.projectName = arr1.projectName
			this.projectName1 = arr1.projectName1
			this.projectName2 = arr1.projectName2
			let levelId = arr1.levelId
			this.obj = {
				"levelId": levelId,
				"page": 1,
				"projectId": this.$route.query.id,
				"size": 10,
				"type": 0
			}
			this.info()
		},
		methods: {
			back() {
				this.$router.go(-1); //返回上一层
			},
			Hj(id) {
				this.index_c = id
				this.obj = {
					"levelId": '',
					"page": 1,
					"projectId": this.$route.query.id,
					"size": 10,
					"type": id
				}
				this.info()
			},
			To(item) {
				let arr1 = {
					projectName: this.projectName,
					projectName1: this.projectName1,
					projectName2: this.projectName2
				}
				let arr = encodeURIComponent(JSON.stringify(arr1))
				this.$router.push('/gjsz_5?id=' + item.id + "&type=" + item.type + "&projectName=" + arr)
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.obj.size = val
				this.info()

			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.obj.page = val
				this.info()
			},
			xian(item) {
				item.qr = !item.qr
			},
			info() {
				this.$ajax('sbgl', 'post', this.obj).then(res => {
					console.log(res);
					this.Data = res.data.records
					this.total = res.data.total

				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.bg {
		background-color: rgba(43, 201, 222, 0.6);
	}

	.bg4 {
		color: rgba(43, 201, 222, 1);
		border-bottom: 1px solid rgba(43, 201, 222, 0.6);
	}

	.bg3 {
		color: rgba(43, 222, 201, 0.4);
	}

	.bg1 {
		background-color: rgba(162, 162, 162, 0.2);
	}

	.bg2 {
		background: rgba(14, 242, 242, 0.3);
	}

	.m1 {
		position: relative;
		color: #FFFFFF;
		margin: 1rem 0 0 .3rem;
		width: 156.6rem;

		// height: 59.4rem;

		.hd {
			position: relative;
			width: 100%;
			height: 5rem;


			.h1 {
				display: inline-block;
				// width: 11.5rem;
				height: 5rem;
				line-height: 5rem;
				padding-left: 1rem;
				font-size: 1.8rem;
			}

			.h2 {
				position: absolute;
				top: 1rem;
				right: 2rem;
				width: 11.5rem;
				height: 4.2rem;
				background-color: rgba(43, 201, 222, 1);
				line-height: 4.2rem;
				text-align: center;
				border-radius: .5rem;
			}
		}

		.lb {
			position: relative;
			// border: 1px solid #2BDEC9;
			margin-top: 1rem;
			font-size: 1.4rem;
			// overflow: auto;

			.hd {
				width: 100%;
				height: 3.6rem;
				line-height: 3.6rem;
				// padding-left: 1rem;
				font-size: 1.4rem;

				// border-bottom: 1px solid #2BDEC9;
				.hd_1 {
					display: inline-block;
					width: 4%;
					height: 4.2rem;
					line-height: 4.2rem;
					text-align: center;
					margin-right: .3%;
					// background-color: rgba(43, 201, 222, 0.6);
				}

				.hd_2 {
					display: inline-block;
					width: 94.5%;
					height: 4.2rem;
					line-height: 4.2rem;
					// background-color: rgba(43, 201, 222, 0.6);
					margin-right: .3%;
				}

				.zh {
					margin-left: 1rem;
					display: inline-block;
					width: 16.8rem;
					overflow: hidden;
				}
			}

			.hd:last-child {
				// border-bottom: none;
			}

			.qx {
				position: absolute;
				top: .1rem;
				left: 50rem;
				display: inline-block;

				.lst {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					background-color: rgba(43, 201, 222, 0.6);
					text-align: center;
					border-radius: .3rem;
					margin-right: 1rem;
				}
			}

			.xg {
				position: absolute;
				top: .5rem;
				right: 2rem;
				width: 27rem;
				height: 2.6rem;
				line-height: 2.6rem;

				.gg {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					text-align: center;
					background-color: rgba(28, 184, 184, 1);
					border-radius: .4rem;
					margin-left: 1rem;
				}
			}
		}

		.main {
			position: relative;
			width: 100%;
			// height: 4.2rem;
			// margin-top: 1rem;
			padding: .5rem 0;

			.m_1 {
				display: inline-block;
				width: 4%;
				height: 4.2rem;
				line-height: 4.2rem;
				text-align: center;
				margin-right: .3%;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
			}

			.m_2 {
				display: inline-block;
				width: 94.5%;
				height: 4.2rem;
				line-height: 4.2rem;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
				margin-right: .3%;
			}
			
			.z1 {
			
				.hidden-text {
					font-size: 1.7rem;
					position: relative;
					line-height: 2.2rem;
					z-index: -1;
					top: -4.2rem;
					padding: 8px 8px 8px 8px;
					border-radius: .2rem;
				}
			}
			
			.z1:hover .hidden-text {
				word-wrap:break-word;
				background-color: rgba(8, 157, 157, 1);
				z-index: 999;
			}

			.m_3 {
				position: relative;
				box-sizing: border-box;
				float: right;
				width: 94.5%;
				height: 4.2rem;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
				margin-top: .5rem;
				line-height: 4.2rem;

				.zh {
					margin-left: 1rem;
					display: inline-block;
					width: 16.8rem;
					overflow: hidden;
				}

				.qx {
					position: absolute;
					top: .1rem;
					left: 50rem;
					display: inline-block;

					.lst {
						display: inline-block;
						width: 6.6rem;
						height: 2.6rem;
						line-height: 2.6rem;
						background-color: rgba(43, 201, 222, 0.6);
						text-align: center;
						border-radius: .3rem;
						margin-right: 1rem;
					}
				}

				.xg {
					position: absolute;
					top: .5rem;
					right: 2rem;
					width: 27rem;
					height: 2.6rem;
					line-height: 2.6rem;

					.gg {
						display: inline-block;
						width: 6.6rem;
						height: 2.6rem;
						line-height: 2.6rem;
						text-align: center;
						background-color: rgba(28, 184, 184, 1);
						border-radius: .4rem;
						margin-left: 1rem;
					}
				}
			}

			.m_3:last-child {
				margin-bottom: 1rem;
			}
		}

		.main:hover {
			background-color: rgba(117, 117, 117, 0.4);
		}
	}

	.back {
		position: absolute;
		top: 1rem;
		right: 2rem;
		border: 1px solid #1CB8B8;
		border-radius: 0.5rem;
		width: 6.7rem;
		height: 3.8rem;
		line-height: 3.8rem;
		text-align: center;
		color: rgba(28, 184, 184, 1);
		font-size: 1.4rem;
		margin-right: 2rem;
		cursor: pointer;
	}
</style>
